import {
  type ClientLoaderFunctionArgs,
  redirect,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTitle } from 'react-use';

import { useUserAnalytics } from '../analytics/user';
import { GuestLoginModal, GuestLoginModalWithOrg } from '../components/Access';
import { GlobalLoading } from '../components/GlobalLoading';
import { ProvidersList } from '../components/ProvidersList';
import {
  useIsUserLoaded,
  UserContextProvider,
  useUser,
} from '../components/UserContext';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { isUnauthenticated } from '../utils/router';

function Bootstrap() {
  useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();
  const navigate = useNavigate();
  const data = useLoaderData<typeof clientLoader>();

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (step === 1) {
      if (data.redirectTo) {
        window.location.href = data.redirectTo;
      } else {
        navigate('/');
      }
    }
  }, [data.redirectTo, navigate, step]);

  if (!isUserLoaded) return <GlobalLoading debug='guest-bootstrap' />;

  return data.asOrg ? (
    <GuestLoginModalWithOrg setStep={setStep} asOrg={data.asOrg} />
  ) : (
    <GuestLoginModal conversion={false} setStep={setStep} />
  );
}

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const redirectTo = url.searchParams.get('redirect-to');

  try {
    // If we already have a user, redirect instead of allowing a new guest user.
    await apiService.auth.verify();
    if (redirectTo) throw redirect(redirectTo);
  } catch (e) {
    if (!isUnauthenticated(e)) throw e;
  }

  let asOrg = null;
  if (redirectTo) {
    const redirectToUrl = new URL(redirectTo);
    const asOrgId = redirectToUrl.searchParams.get('as-org-id');

    if (asOrgId) {
      try {
        const resp = await apiService.organization.getPublicOrganization(
          asOrgId
        );
        asOrg = resp.data.organization;
      } catch (e) {
        // If the org is not found, we will just ignore it.
      }
    }
  }

  return { redirectTo, asOrg };
}

export function Component() {
  useTitle(makeTitle('Guest'));

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Bootstrap />
    </ProvidersList>
  );
}
